import * as React from "react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "utils/linkResolver"
import {
  mainImage,
  yellSection,
  yellBg,
  contactDetail,
} from "./styles.module.css"
import Imgix from "react-imgix"
import { Helmet } from "react-helmet"
import { AnFadeIn } from "components"
import { graphql } from "gatsby"

const ContactPage = ({ data }) => {
  const { email, image, number, title } =
    data.allPrismicContactPage.nodes[0].data

  return (
    <AnFadeIn>
      <section className={yellBg}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Still Curious | Contact`}</title>
        </Helmet>
        <Imgix src={image.url} alt="Contact us" className={mainImage} />
        <section className={yellSection}>
          <h1>{title.text}</h1>
          <p className={contactDetail}>
            <a href={`tel:+${number.text}`}>{number.text}</a>
          </p>
          <p className={contactDetail}>
            <a href={`mailto:${email.text.toLowerCase()}`}>{email.text}</a>
          </p>
        </section>
      </section>
    </AnFadeIn>
  )
}

export const query = graphql`
  query ContactQuery {
    allPrismicContactPage {
      nodes {
        data {
          email {
            text
          }
          image {
            url
          }
          number {
            text
          }
          title {
            text
          }
        }
      }
    }
  }
`

export default withPrismicPreview(ContactPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
